import React, { useState, useEffect } from 'react';
import { Book, AlertCircle } from 'lucide-react';

const HorrorBookshelf = () => {
  // Sample book data for the horror book club
  const [books, setBooks] = useState([
    {
      id: 1,
      title: "House of Leaves",
      author: "Mark Z. Danielewski",
      year: 2000,
      description: "A labyrinthine exploration of a house that's bigger on the inside than the outside, told through multiple narrators and unique typographical layouts.",
      coverImage: "house-of-leaves",
      rating: 4.5,
      clubRating: 4.2,
      discussionDate: "March 15, 2023",
      notes: "Discussion focused on narrative structure and the use of typography as a storytelling device.",
      selected: false,
      color: "#a91e1e"
    },
    {
      id: 2,
      title: "The Haunting of Hill House",
      author: "Shirley Jackson",
      year: 1959,
      description: "Four seekers arrive at the notoriously unfriendly Hill House: Dr. Montague, an occult scholar; Theodora, his lighthearted assistant; Eleanor, a fragile young woman; and Luke, the future heir of Hill House.",
      coverImage: "hill-house",
      rating: 4.7,
      clubRating: 4.8,
      discussionDate: "October 28, 2023",
      notes: "Members were particularly drawn to the psychological aspects of the haunting versus supernatural interpretation.",
      selected: false,
      color: "#41315e"
    },
    {
      id: 3,
      title: "Pet Sematary",
      author: "Stephen King",
      year: 1983,
      description: "When Dr. Louis Creed takes a new job and moves his family to Ludlow, Maine, this new beginning seems too good to be true. But a burial ground hidden deep in the surrounding forest will introduce the family to a darkness they never expected.",
      coverImage: "pet-sematary",
      rating: 4.3,
      clubRating: 4.6,
      discussionDate: "January 7, 2024",
      notes: "Discussions centered around grief and the moral implications of resurrection.",
      selected: false,
      color: "#1a3b1d"
    },
    {
      id: 4,
      title: "Mexican Gothic",
      author: "Silvia Moreno-Garcia",
      year: 2020,
      description: "After receiving a frantic letter from her newly-wed cousin, Noemí Taboada heads to High Place, a distant house in the Mexican countryside where her cousin's husband, a handsome Englishman, has secrets.",
      coverImage: "mexican-gothic",
      rating: 4.0,
      clubRating: 4.4,
      discussionDate: "April 30, 2024",
      notes: "The club appreciated the blend of gothic horror with colonialism critique.",
      selected: false,
      color: "#8e3655"
    },
    {
      id: 5,
      title: "Frankenstein",
      author: "Mary Shelley",
      year: 1818,
      description: "A young scientist's obsession with creating life leads to the birth of a hideous creature and a series of events that forces him to reckon with the moral implications of playing god.",
      coverImage: "frankenstein",
      rating: 4.8,
      clubRating: 4.7,
      discussionDate: "June 15, 2022",
      notes: "Discussion explored themes of creation, responsibility, and what it means to be human.",
      selected: false,
      color: "#1f455e"
    }
  ]);

  const [selectedBook, setSelectedBook] = useState(null);
  const [animation, setAnimation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  // Handle book selection
  const handleBookClick = (id) => {
    // First deselect all books
    const updatedBooks = books.map(book => ({
      ...book,
      selected: book.id === id
    }));
    
    setBooks(updatedBooks);
    
    // Toggle selected book or select new one
    if (selectedBook && selectedBook.id === id) {
      setAnimation(false);
      setTimeout(() => {
        setSelectedBook(null);
      }, 300);
    } else {
      setSelectedBook(books.find(book => book.id === id));
      setAnimation(true);
    }
  };
  
  // Create lightning effect for the background
  const [lightningEffect, setLightningEffect] = useState(false);
  
  useEffect(() => {
    // Random lightning effect
    const lightningInterval = setInterval(() => {
      if (Math.random() > 0.9) {
        setLightningEffect(true);
        setTimeout(() => setLightningEffect(false), 200);
      }
    }, 5000);
    
    return () => clearInterval(lightningInterval);
  }, []);

  // Book component with 3D spine effect
  const BookSpine = ({ book }) => {
    return (
      <div 
        className={`relative h-64 w-14 cursor-pointer transform transition-all duration-500 hover:translate-y-2 rounded-sm ${book.selected ? 'translate-y-4 -rotate-6' : ''}`}
        onClick={() => handleBookClick(book.id)}
      >
        {/* Book spine with gradient */}
        <div 
          className="absolute inset-0 rounded-sm shadow-lg"
          style={{ 
            background: `linear-gradient(to right, ${book.color} 90%, #00000050 100%)`,
          }}
        >
          {/* Spine texture */}
          <div className="absolute inset-0 opacity-10 bg-repeat" 
               style={{ backgroundImage: "url('/api/placeholder/10/10')", backgroundSize: "8px 8px" }} />
          
          {/* Wear and tear details */}
          <div className="absolute top-2 left-1 w-2 h-8 bg-black opacity-5 rounded-full" />
          <div className="absolute bottom-10 left-3 w-3 h-6 bg-black opacity-10 rounded-sm" />
          
          {/* Book title */}
          <div 
            className="absolute h-full p-3 text-sm font-bold writing-mode-vertical flex items-center justify-center"
            style={{ 
              color: "#f5f5f5",
              writingMode: 'vertical-rl',
              textOrientation: 'mixed',
              transform: 'rotate(180deg)',
              textShadow: '1px 1px 2px rgba(0,0,0,0.7)',
              maxHeight: '100%'
            }}
          >
            <span className="truncate max-w-full">{book.title}</span>
          </div>
          
          {/* Year at bottom of spine */}
          <div 
            className="absolute bottom-1 left-0 right-0 text-center text-xs font-semibold text-gray-200 bg-black bg-opacity-30 px-1"
          >
            {book.year}
          </div>
        </div>
        
        {/* Edge details */}
        <div className="absolute right-0 top-0 bottom-0 w-1 bg-black opacity-20" />
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-black opacity-30" />
        <div className="absolute top-0 left-0 right-0 h-1 bg-black opacity-10" />
      </div>
    );
  };

  // Rating component with blood drops
  const BloodRating = ({ rating }) => {
    const drops = 5;
    return (
      <div className="flex items-center gap-1">
        {[...Array(drops)].map((_, i) => (
          <div 
            key={i} 
            className={`h-4 w-4 rounded-b-full transform rotate-45 ${i < Math.round(rating) ? 'bg-[#d32f2f]' : 'bg-gray-300'}`}
            style={{
              clipPath: 'polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 75%)',
              opacity: i < rating ? (0.7 + (i * 0.1)) : 0.3
            }}
          />
        ))}
        <span className="ml-2 text-xs text-gray-400">{rating.toFixed(1)}/5</span>
      </div>
    );
  };

  return (
    <div className={`min-h-screen p-8 bg-[#1b1d24] text-[#e0e0e0] transition-all duration-200 relative overflow-hidden ${lightningEffect ? 'bg-[#232630]' : ''}`}>
      {/* Floating Eldritch Symbols */}
      <div className="fixed inset-0 pointer-events-none overflow-hidden z-0">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute text-[#db2777]/20 animate-float"
            style={{
              fontSize: `${Math.random() * 14 + 8}px`,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 15 + 10}s`,
              animationDelay: `${Math.random() * 5}s`,
            }}
          >
            {['◯', '◭', '△', '▽', '◬', '⛦', '⛥'][Math.floor(Math.random() * 7)]}
          </div>
        ))}
      </div>
      <div className="max-w-6xl mx-auto relative mt-16">
        {/* Header with spooky title */}
        <div className="mb-10 text-center relative">
          <h1 
            className="text-5xl font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#d32f2f] to-[#B22222] font-serif"
            style={{
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              filter: 'drop-shadow(0 0 1px #B22222)',
            }}
          >
            The Haunted Bookshelf
          </h1>
          <p className="text-gray-400 italic">Our Horror Book Club's Collective Reading Journey</p>
          
          {/* Decorative elements */}
          <div className="absolute left-0 top-1/2 w-16 h-px bg-gradient-to-r from-transparent to-[#d32f2f]"></div>
          <div className="absolute right-0 top-1/2 w-16 h-px bg-gradient-to-r from-[#d32f2f] to-transparent"></div>
        </div>
        
        {/* Main content area */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Bookshelf section */}
          <div className="lg:w-2/3 w-full">
            {/* The actual bookshelf with wood texture */}
            <div 
              className="w-full rounded-lg shadow-lg overflow-hidden relative border-2 border-[#0c0b0c]"
              style={{
                backgroundImage: "linear-gradient(to bottom, #3c2415 0%, #5e3a22 100%)",
                boxShadow: '0 10px 25px rgba(0,0,0,0.5)',
              }}
            >
              {/* Bookshelf texture */}
              <div className="absolute inset-0 opacity-30 bg-repeat" 
                   style={{ backgroundImage: "url('/api/placeholder/100/100')", backgroundSize: "50px 50px" }} />
              
              {/* Dark vignette effect */}
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-30"></div>
              <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent opacity-20"></div>
              <div className="absolute inset-0 bg-gradient-to-l from-black to-transparent opacity-20"></div>
              
              {/* Books container */}
              <div className="relative z-10 flex items-end justify-around p-6 min-h-96">
                {books.map(book => (
                  <BookSpine key={book.id} book={book} />
                ))}
              </div>
              
              {/* Shelf edge */}
              <div className="h-8 bg-gradient-to-b from-amber-900 to-amber-950 relative shadow-inner">
                <div className="absolute inset-0 opacity-30 bg-repeat" 
                     style={{ backgroundImage: "url('/api/placeholder/20/20')", backgroundSize: "10px 10px" }} />
              </div>
            </div>
            
            {/* Atmospheric fog effect */}
            <div className="h-12 w-full bg-gradient-to-b from-gray-900 to-transparent opacity-50 -mt-8"></div>
            
              {/* Book detail card that slides up when book is selected */}
              {selectedBook && (
                <div className={`mt-6 bg-[#1e2029] rounded-lg shadow-lg overflow-hidden transition-all duration-500 transform border border-[#0c0b0c] ${animation ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
                <div className="p-6 relative">
                  {/* Background texture */}
                  <div className="absolute inset-0 opacity-5 bg-repeat" 
                       style={{ backgroundImage: "url('/api/placeholder/20/20')", backgroundSize: "10px 10px" }} />
                  
                  <div className="relative z-10">
                    {/* Book header */}
                    <div className="flex flex-col md:flex-row gap-6 mb-6 items-start">
                      {/* Book color sample */}
                      <div 
                        className="w-20 h-28 rounded shadow-lg flex items-center justify-center relative overflow-hidden"
                        style={{ backgroundColor: selectedBook.color }}
                      >
                        {/* Book texture */}
                        <div className="absolute inset-0 opacity-10 bg-repeat" 
                             style={{ backgroundImage: "url('/api/placeholder/10/10')", backgroundSize: "8px 8px" }} />
                        
                        <span className="text-white text-opacity-80 text-center text-sm font-bold p-2 rotate-0">
                          {selectedBook.title}
                        </span>
                      </div>
                      
                      {/* Book info */}
                      <div className="flex-1">
                        <h2 
                          className="text-3xl font-bold font-serif mb-1 text-[#d32f2f]"
                          style={{
                            textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                          }}
                        >
                          {selectedBook.title}
                        </h2>
                        <p className="text-gray-400 mb-3">by {selectedBook.author} • {selectedBook.year}</p>
                        
                        {/* Ratings with custom blood drop indicators */}
                        <div className="flex flex-col gap-2 mb-4">
                          <div className="flex items-center gap-4">
                            <span className="text-xs text-gray-500 font-semibold uppercase">Goodreads:</span>
                            <BloodRating rating={selectedBook.rating} />
                          </div>
                          <div className="flex items-center gap-4">
                            <span className="text-xs text-gray-500 font-semibold uppercase">Club Rating:</span>
                            <BloodRating rating={selectedBook.clubRating} />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {/* Book description */}
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold mb-2 text-gray-300">Synopsis</h3>
                      <p className="text-gray-400 leading-relaxed">{selectedBook.description}</p>
                    </div>
                    
                    {/* Club discussion details */}
                    <div className="p-4 bg-[#2b2b2b] rounded border border-[#0c0b0c] mb-6">
                      <div className="flex items-start gap-3">
                        <Book size={20} className="text-[#d32f2f] mt-1" />
                        <div>
                          <h3 className="text-md font-semibold text-gray-300">Club Discussion</h3>
                          <p className="text-gray-500 text-sm mb-2">Discussed on {selectedBook.discussionDate}</p>
                          <p className="text-gray-400 text-sm italic">{selectedBook.notes}</p>
                        </div>
                      </div>
                    </div>
                    
                    {/* Action buttons */}
                    <div className="flex flex-wrap gap-3">
                      <button className="px-4 py-2 bg-[#d32f2f] hover:bg-[#b71c1c] text-white rounded-md text-sm font-medium transition-all duration-200 flex items-center gap-2 shadow-lg">
                        <span>Add to Reading List</span>
                      </button>
                      <button 
                        className="px-4 py-2 bg-[#2b2b2b] hover:bg-[#3b3b3b] text-white rounded-md text-sm font-medium transition-all duration-200 flex items-center gap-2"
                        onClick={() => setShowModal(true)}
                      >
                        <span>Share Thoughts</span>
                      </button>
                      <button className="px-4 py-2 bg-transparent border border-gray-600 hover:border-gray-500 text-gray-300 rounded-md text-sm font-medium transition-all">
                        <span>View Annotations</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          
          {/* Side panel with club info */}
          <div className="lg:w-1/3 w-full">
            <div className="bg-[#1e2029] rounded-lg shadow-lg overflow-hidden border-2 border-[#0c0b0c]">
              {/* Club panel header */}
              <div className="p-5 bg-gradient-to-r from-[#1b1d24] to-[#1e2029] border-b border-[#0c0b0c]">
                <h2 
                  className="text-xl font-serif font-bold text-[#db2777]"
                  style={{
                    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                  }}
                >
                  Horror Book Club
                </h2>
                <p className="text-gray-400 text-sm">Exploring the darkness together since 2021</p>
              </div>
              
              {/* Club stats */}
              <div className="p-5">
                <h3 className="text-lg font-semibold mb-3 text-[#f5f5f5]">Club Statistics</h3>
                
                <div className="space-y-4">
                  {/* Books read stat */}
                  <div className="bg-[#2b2b2b] rounded-lg p-4 flex items-center gap-4">
                    <div className="w-12 h-12 rounded-full bg-[#d32f2f] flex items-center justify-center">
                      <Book size={24} className="text-white" />
                    </div>
                    <div>
                      <h4 className="text-2xl font-bold text-gray-200">5</h4>
                      <p className="text-gray-500 text-sm">Books Completed</p>
                    </div>
                  </div>
                  
                  {/* Next meeting */}
                  <div className="bg-[#2b2b2b] rounded-lg p-4">
                    <h4 className="text-md font-semibold text-[#f5f5f5] mb-2">Next Discussion</h4>
                    <p className="text-[#d32f2f] font-bold mb-1">The Shining by Stephen King</p>
                    <p className="text-gray-400 text-sm">March 15, 2025 • 7:00 PM</p>
                    <div className="mt-3 flex items-center text-gray-500 text-xs">
                      <AlertCircle size={14} className="mr-1" />
                      <span>2 weeks remaining to finish</span>
                    </div>
                  </div>
                  
                  {/* Reading year progress */}
                  <div>
                    <div className="flex justify-between mb-1 text-sm">
                      <span className="text-gray-400">2025 Reading Progress</span>
                      <span className="text-gray-500">2 of 12 books</span>
                    </div>
                    <div className="w-full bg-[#3b3b3b] rounded-full h-2.5">
                      <div className="bg-[#d32f2f] h-2.5 rounded-full" style={{ width: '8%' }}></div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Filter controls */}
              <div className="p-5 border-t border-[#0c0b0c]">
                <h3 className="text-md font-semibold mb-3 text-[#f5f5f5]">Filter Books</h3>
                <div className="flex flex-wrap gap-2">
                  <button className="px-3 py-1.5 bg-[#2b2b2b] hover:bg-[#3b3b3b] text-[#e0e0e0] rounded-md text-xs transition-all">
                    By Year
                  </button>
                  <button className="px-3 py-1.5 bg-[#2b2b2b] hover:bg-[#3b3b3b] text-[#e0e0e0] rounded-md text-xs transition-all">
                    By Author
                  </button>
                  <button className="px-3 py-1.5 bg-[#2b2b2b] hover:bg-[#3b3b3b] text-[#e0e0e0] rounded-md text-xs transition-all">
                    By Rating
                  </button>
                  <button className="px-3 py-1.5 bg-[#d32f2f] hover:bg-[#b71c1c] text-white rounded-md text-xs transition-all">
                    Add New Book
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Spooky footer */}
        <div className="mt-12 text-center text-gray-500 text-xs">
          <p>"Every bookshelf is a journey into the human psyche."</p>
          <p className="mt-1">© 2025 Horror Book Club</p>
        </div>
      </div>
      
      {/* Modal for sharing thoughts (hidden by default) */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4">
          <div className="bg-[#1e2029] rounded-lg shadow-2xl max-w-lg w-full p-6 relative border-2 border-[#0c0b0c] animate-fade-in">
            <button 
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-300"
              onClick={() => setShowModal(false)}
            >
              ✕
            </button>
            <h3 className="text-xl font-bold mb-4 text-[#f5f5f5]">Share Your Thoughts</h3>
            <p className="text-gray-400 mb-4">What did you think about "{selectedBook?.title}"?</p>
            
            <textarea 
              className="w-full bg-[#2b2b2b] border border-[#0c0b0c] rounded-md p-3 text-[#e0e0e0] focus:outline-none focus:border-[#db2777] focus:ring-1 focus:ring-[#db2777] mb-4 h-32"
              placeholder="Type your thoughts here..."
            ></textarea>
            
            <div className="flex justify-between">
              <button 
                className="px-4 py-2 bg-transparent hover:bg-[#2b2b2b] text-gray-400 rounded-md text-sm transition-all"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button className="px-4 py-2 bg-[#d32f2f] hover:bg-[#b71c1c] text-white rounded-md text-sm transition-all">
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HorrorBookshelf;
