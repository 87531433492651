import React, { useState } from 'react';
import './rating.css';

const TagSelector = ({ availableTags, selectedTags, onTagsChange, isMobile }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleTagClick = (tagId) => {
    if (selectedTags.includes(tagId)) {
      onTagsChange(selectedTags.filter(id => id !== tagId));
    } else {
      onTagsChange([...selectedTags, tagId]);
    }
  };

  const filteredTags = availableTags.filter(tag =>
    tag.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={`tag-selector ${isMobile ? 'mobile' : ''}`}>
      <div className={`tag-search ${isMobile ? 'mobile' : ''}`}>
        <input
          type="text"
          placeholder="Search tags..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="tag-search-input"
        />
      </div>
      
      <div className={`tags-container ${isMobile ? 'mobile' : ''}`}>
        {filteredTags.map((tag) => (
          <button
            key={tag.tag_id}
            type="button"
            className={`tag-button ${selectedTags.includes(tag.tag_id) ? 'selected' : ''} ${isMobile ? 'mobile' : ''}`}
            onClick={() => handleTagClick(tag.tag_id)}
          >
            {tag.name}
          </button>
        ))}
      </div>

      {selectedTags.length > 0 && (
        <div className={`selected-tags ${isMobile ? 'mobile' : ''}`}>
          <h4>Selected Tags:</h4>
          <div className={`selected-tags-container ${isMobile ? 'mobile' : ''}`}>
            {selectedTags.map((tagId) => {
              const tag = availableTags.find(t => t.tag_id === tagId);
              return tag ? (
                <span key={tag.tag_id} className={`selected-tag ${isMobile ? 'mobile' : ''}`}>
                  {tag.name}
                  <button
                    type="button"
                    className={`remove-tag ${isMobile ? 'mobile' : ''}`}
                    onClick={() => handleTagClick(tag.tag_id)}
                  >
                    ×
                  </button>
                </span>
              ) : null;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagSelector;
