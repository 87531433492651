import React, { useState, useEffect } from 'react';
import './rating.css';

const StarRating = ({ rating, onRatingChange }) => {
  const [hoverRating, setHoverRating] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Detect mobile and orientation
  useEffect(() => {
    const checkMobileAndOrientation = () => {
      const mobile = window.innerWidth < 768 || /Mobi|Android/i.test(navigator.userAgent);
      const landscape = window.innerWidth > window.innerHeight;
      setIsMobile(mobile);
      setIsLandscape(landscape);
    };

    checkMobileAndOrientation();
    window.addEventListener('resize', checkMobileAndOrientation);
    window.addEventListener('orientationchange', checkMobileAndOrientation);

    return () => {
      window.removeEventListener('resize', checkMobileAndOrientation);
      window.removeEventListener('orientationchange', checkMobileAndOrientation);
    };
  }, []);

  // Improved rating calculation with mobile correction
  const calculateRating = (clientX, element, isTouch = false) => {
    const { left, width } = element.getBoundingClientRect();
    
    // Apply correction for the observed leftward shift on mobile touch
    let x = clientX - left;
    
    // Add correction factor for mobile touch events
    if (isTouch && isMobile) {
      // Apply stronger correction in landscape mode
      const correctionFactor = isLandscape ? 0.2 : 0.1;
      const correction = width * correctionFactor;
      x += correction; // Shift touch position right to compensate for leftward bias
    }
    
    // Ensure x is within bounds
    x = Math.max(0, Math.min(width, x));
    
    const starWidth = width / 5;
    const starIndex = Math.floor(x / starWidth);
    const starPercent = (x % starWidth) / starWidth;
    
    let newRating = starIndex + 1;
    
    // Simplified half-star logic for mobile
    if (isMobile) {
      // On mobile, prefer full stars for better touch accuracy
      if (starPercent < 0.4) {
        newRating -= 0.5;
      }
    } else {
      // Desktop behavior with more precise half-star detection
      if (starPercent < 0.3) {
        newRating -= 0.5;
      } else if (starPercent < 0.7) {
        newRating = Math.floor(newRating);
      }
    }
    
    return Math.max(0.5, Math.min(5, newRating));
  };

  // Mouse event handlers (for desktop)
  const handleMouseMove = (e) => {
    if (!isDragging || isMobile) return;
    const newRating = calculateRating(e.clientX, e.currentTarget);
    setHoverRating(newRating);
  };

  const handleMouseDown = (e) => {
    if (isMobile) return;
    setIsDragging(true);
    handleMouseMove(e);
  };

  const handleMouseUp = () => {
    if (isMobile) return;
    if (isDragging && hoverRating > 0) {
      onRatingChange(hoverRating);
    }
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    if (isMobile) return;
    setIsDragging(false);
    setHoverRating(0);
  };

  // Improved touch event handlers
  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevent mouse events from firing
    const touch = e.touches[0];
    const newRating = calculateRating(touch.clientX, e.currentTarget, true);
    setHoverRating(newRating);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const touch = e.touches[0];
    const newRating = calculateRating(touch.clientX, e.currentTarget, true);
    setHoverRating(newRating);
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    if (hoverRating > 0) {
      onRatingChange(hoverRating);
    }
    setIsDragging(false);
  };

  // Improved star click handler with better mobile support
  const handleStarClick = (index, e) => {
    // Prevent default to avoid double-triggering on mobile
    e.preventDefault();
    
    // For touch events on mobile, use the corrected index
    if (isMobile) {
      // On mobile, set the full star rating when tapped
      onRatingChange(index + 1);
      return;
    }
    
    // For mouse events on desktop, keep the half-star precision
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const starPercent = x / rect.width;
    
    let newRating = index + 1;
    if (starPercent < 0.5) {
      newRating -= 0.5;
    }
    
    onRatingChange(newRating);
  };

  const displayRating = hoverRating || rating;

  return (
    <div
      className={`star-rating ${isMobile ? 'mobile' : ''} ${isLandscape ? 'landscape' : ''}`}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {[1, 2, 3, 4, 5].map((index) => (
        <div
          key={index}
          className="star-container"
          onClick={(e) => handleStarClick(index - 1, e)}
        >
          <svg
            className={`star ${
              displayRating >= index ? 'filled' :
              displayRating >= index - 0.5 ? 'half-filled' : ''
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <defs>
              <linearGradient id={`half-fill-${index}`}>
                <stop offset="50%" stopColor="#FFD700" />
                <stop offset="50%" stopColor="#e4e5e9" />
              </linearGradient>
            </defs>
            <path
              fill={displayRating >= index ? '#FFD700' :
                    displayRating >= index - 0.5 ? `url(#half-fill-${index})` : '#e4e5e9'}
              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
            />
          </svg>
        </div>
      ))}
      <span className="rating-value">
        {displayRating ? displayRating.toFixed(1) : '0.0'}
      </span>
    </div>
  );
};

export default StarRating;
